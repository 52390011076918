import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Adding your Full HD Kameras Videostream to your Website",
  "path": "/Advanced_User/Website_Integration/HD_Camera_Integration/",
  "dateChanged": "2020-03-17",
  "author": "Mike Polinowski",
  "excerpt": "Adding your FUll HD Kameras Videostream to your Website",
  "image": "./AU_SearchThumb_WebsiteIntegration_HD_Cams.png",
  "social": "/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Integration_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Adding your Full HD Kameras Videostream to your Website' dateChanged='2020-03-17' author='Mike Polinowski' tag='INSTAR IP Camera' description='Insert the live video from your Full HD camera into your website.' image='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.png' twitter='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Webseiten_Einbindung/HD_Kamera_Einbindung/' locationFR='/fr/Advanced_User/Website_Integration/HD_Camera_Integration/' crumbLabel="Website" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "adding-your-full-hd-kameras-videostream-to-your-website",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#adding-your-full-hd-kameras-videostream-to-your-website",
        "aria-label": "adding your full hd kameras videostream to your website permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your Full HD Kameras Videostream to your Website`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#integration-of-the-live-video-stream-in-your-website"
        }}>{`Integration of the live video stream in your website`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#edit-the-html-page"
            }}>{`Edit the HTML Page`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-the-h264-videostream"
            }}>{`Adding the h.264 Videostream`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-the-mjpeg-videostream"
            }}>{`Adding the MJPEG Videostream`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#testing-offline"
        }}>{`Testing Offline`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#upload-the-page-to-your-server"
        }}>{`Upload the Page to your Server`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "integration-of-the-live-video-stream-in-your-website",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#integration-of-the-live-video-stream-in-your-website",
        "aria-label": "integration of the live video stream in your website permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integration of the live video stream in your website`}</h2>
    <p>{`There is two ways to add the video stream of your camera to your website.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`h264`}</strong>{`: This method will use the h.264 video stream of your camera. The h.264 requires the `}<a parentName="li" {...{
          "href": "/en/Quick_Installation/Live_Video/"
        }}>{`Flash Plugin`}</a>{` installed inside your web browser. If you are still using an older camera model that uses the INSTAR ActiveX Plugin - `}<a parentName="li" {...{
          "href": "/en/Advanced_User/Website_Integration/HD_Camera_Integration/ActiveX/"
        }}>{`please follow this guide instead`}</a></li>
      <li parentName="ul"><strong parentName="li">{`MJPEG`}</strong>{`: This method will work with every web browser without the need for a video plugin.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: In order to access your camera over the internet you need to `}<a parentName="p" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
        }}>{`forward the HTTP and RTMP Port`}</a>{` of your camera `}<a parentName="p" {...{
          "href": "/en/Internet_Access/Port_Forwarding/"
        }}>{`inside your Router`}</a>{` and use your camera's `}<a parentName="p" {...{
          "href": "/en/Internet_Access/The_DDNS_Service/"
        }}>{`DDNS Address`}</a>{` instead of the local IP address. The following example only shows the use of the local IP.`}</p>
    </blockquote>
    <h3 {...{
      "id": "edit-the-html-page",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#edit-the-html-page",
        "aria-label": "edit the html page permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Edit the HTML Page`}</h3>
    <p>{`Please download our HTML package and unpack the ZIP file. The ZIP file can be found `}<a parentName="p" {...{
        "href": "/en/dl/Developer/INSTAR_Video_in_Website_Flash.zip"
      }}>{`here`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "690px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f22cb60f432ea55d67f223ea7230824f/1e043/Stream_in_Website_Flash_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA7ElEQVQY03WQwW6DMBBE/f9/1UsUqaeiYFpIIZDaYOxd2zjYmAZcpb1VyjvMaZ40GiKHEQGUVKMQcpRKSqXAudtkJ2vtuq4ppf0J5Nge87w7d0Nx7U5l9Zad2YmO1xq08D64m7PPIVWTfVbFe1mVZUUpzXNKs+yjoE1Tc8Y546i1McZaa4yZpsecvzTGECFbq0drJwAFCgBQwaOPqEEBIoYQYozOueA9IhpjNWo3uWWJ5PL6Ujdt8H7btvSUnTEW4+rnmTM+CqFRL8tC4HD44ry7tH6eU0rbtv175dfdAfH7fk8paa2Hoed9DwA/2VNQf7wUvXMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f22cb60f432ea55d67f223ea7230824f/e4706/Stream_in_Website_Flash_01.avif 230w", "/en/static/f22cb60f432ea55d67f223ea7230824f/d1af7/Stream_in_Website_Flash_01.avif 460w", "/en/static/f22cb60f432ea55d67f223ea7230824f/679d2/Stream_in_Website_Flash_01.avif 690w"],
              "sizes": "(max-width: 690px) 100vw, 690px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f22cb60f432ea55d67f223ea7230824f/a0b58/Stream_in_Website_Flash_01.webp 230w", "/en/static/f22cb60f432ea55d67f223ea7230824f/bc10c/Stream_in_Website_Flash_01.webp 460w", "/en/static/f22cb60f432ea55d67f223ea7230824f/8efd0/Stream_in_Website_Flash_01.webp 690w"],
              "sizes": "(max-width: 690px) 100vw, 690px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f22cb60f432ea55d67f223ea7230824f/81c8e/Stream_in_Website_Flash_01.png 230w", "/en/static/f22cb60f432ea55d67f223ea7230824f/08a84/Stream_in_Website_Flash_01.png 460w", "/en/static/f22cb60f432ea55d67f223ea7230824f/1e043/Stream_in_Website_Flash_01.png 690w"],
              "sizes": "(max-width: 690px) 100vw, 690px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f22cb60f432ea55d67f223ea7230824f/1e043/Stream_in_Website_Flash_01.png",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Download and unzip the files.`}</p>
    <h3 {...{
      "id": "adding-the-h264-videostream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-the-h264-videostream",
        "aria-label": "adding the h264 videostream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the h.264 Videostream`}</h3>
    <p>{`If you want to use the h.264 video stream in your website please open the file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h264_video.html`}</code>{` and make the following modifications for your camera:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
	`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
		`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`INSTAR HD und FullHD Kamera`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
	
		`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`embed`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`http://192.168.1.116:80/flash/StrobeMediaPlayback.swf`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` 
		`}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`mode`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`flash`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` 	
		`}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`flashvars`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span><span parentName="span" {...{
                "className": "token entity named-entity",
                "title": "&"
              }}>{`&amp;`}</span>{`src=rtmp://192.168.1.116:1935/flash/12:YWRtaW46aW5zdGFy`}<span parentName="span" {...{
                "className": "token entity named-entity",
                "title": "&"
              }}>{`&amp;`}</span>{`streamType=live`}<span parentName="span" {...{
                "className": "token entity named-entity",
                "title": "&"
              }}>{`&amp;`}</span>{`autoPlay=true`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` 
		`}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`application/x-shockwave-flash`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` 
		`}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`640`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` 
		`}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`352`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
	`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`In this example I added a camera with the following information (please change them according to your camera setup):`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`local IP address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`192.168.1.116`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`http port`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`80`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rtmp port`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`1935`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`username`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`admin`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`instar`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`base64 login`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin:instar => `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`YWRtaW46aW5zdGFy`}</code></td>
        </tr>
      </tbody>
    </table>
    <p>{`As you can see in the Flash video URL you have to base64 encode the user login. This can be done on the `}<a parentName="p" {...{
        "href": "https://www.base64encode.org"
      }}>{`www.base64encode.org`}</a>{` website:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "837px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6e8f8d344bec81de88f2b46f4f14b088/ddc81/Stream_in_Website_Flash_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACmklEQVQoz52Q20sUURzH5y+I1Zewopceopck0SzpwUIjlFoDLaIeim5e2lDRzKxcreypgiIW7666au3FLcyUhQgvK1EpFmmZVKK4OzNnzpnrmdmZ2Z2YtkgKkfqcH4cvHD78zu9HHOvZfLBj3bVha20gvz5QUNCdZPPvvjpsveLPtremVz/Jqh7KqX6eUzV4oHIgq/xpps2fcd6746wn+ZwnmTjq2niib0vFwL6qZ/trhnJPube1BWtcbx/2tJye3WQZvJnfNfvANXGrc6LBGbzRNlbXOmJvGbE3v6xtenGdONK94WTvVpsno8S9s8yfWebfe9G3p3Igu9Szq6J1e6E7uciXWuhNKfallfjSin1pRd7UQk/Khf6MYl86cbgz4f5I6fzC4qeFufcz0x8/z8zOz0AeYFWQDCzrkqyJfxTWBFVXaHGJONSV2BEoF8em2MlpSVIkQZJELPCSgiO6GtVU/e9SVS2qGUikCWtXYmPARk9+WH43jTgO/gAh82ZWATAAQXaR/EJYnZb2N3WKakDhp7kmDGRYxC1RXwmrM6Fx/BLmFACo/5HbX9ViPgIY8M9yXofF+bpeFjQGArSC1UzzCUGO5Zeob+bMTeNVIpIhgnEHsSaCKAqCwPN8PMSRJByNRnVdN2IGi2nz247RCnoZIhbyPE+SJBkmSYpCCBmrEDNihmHwCkNY2xNagpcR4MNkiKIpiiQBbZ4wGZJlZQ05t9vimKrReANQkIMCoGiKpmlAk1QYQqSqqvIbWdM0U479kkvuru+7d9wd7O0fdXnHXSE6xLEcAxiEEAPM/WOMZVlWZEWSJFVVV8iQOONI6ryT97i5wd12+5HDHp6fEyTMsojnOJZlRVGMRCLxvvEgyzKWcURRaS70HfliC4/e7QxbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6e8f8d344bec81de88f2b46f4f14b088/e4706/Stream_in_Website_Flash_02.avif 230w", "/en/static/6e8f8d344bec81de88f2b46f4f14b088/d1af7/Stream_in_Website_Flash_02.avif 460w", "/en/static/6e8f8d344bec81de88f2b46f4f14b088/60ad0/Stream_in_Website_Flash_02.avif 837w"],
              "sizes": "(max-width: 837px) 100vw, 837px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6e8f8d344bec81de88f2b46f4f14b088/a0b58/Stream_in_Website_Flash_02.webp 230w", "/en/static/6e8f8d344bec81de88f2b46f4f14b088/bc10c/Stream_in_Website_Flash_02.webp 460w", "/en/static/6e8f8d344bec81de88f2b46f4f14b088/a6125/Stream_in_Website_Flash_02.webp 837w"],
              "sizes": "(max-width: 837px) 100vw, 837px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6e8f8d344bec81de88f2b46f4f14b088/81c8e/Stream_in_Website_Flash_02.png 230w", "/en/static/6e8f8d344bec81de88f2b46f4f14b088/08a84/Stream_in_Website_Flash_02.png 460w", "/en/static/6e8f8d344bec81de88f2b46f4f14b088/ddc81/Stream_in_Website_Flash_02.png 837w"],
              "sizes": "(max-width: 837px) 100vw, 837px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6e8f8d344bec81de88f2b46f4f14b088/ddc81/Stream_in_Website_Flash_02.png",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The player is then downloaded from:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.1.116:80/flash/StrobeMediaPlayback.swf`}</code></li>
    </ul>
    <p>{`And uses your cameras RTMP stream from:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`rtmp://192.168.1.116:1935/flash/12:YWRtaW46aW5zdGFy&amp;streamType=live&amp;autoPlay=true`}</code></li>
    </ul>
    <h3 {...{
      "id": "adding-the-mjpeg-videostream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-the-mjpeg-videostream",
        "aria-label": "adding the mjpeg videostream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the MJPEG Videostream`}</h3>
    <p>{`If you want to use the MJPEG stream instead, please modify the file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mjpeg_video.html`}</code>{` in the same way as explained above:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`////////////////////////////////////////////////////////////////////////////////`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// DIESE PARAMETER M�SSEN ENTSPRECHEND AN DIE IHRER KAMERA ANGEPASST WERDEN`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// START KAMERA PARAMETER`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`////////////////////////////////////////////////////////////////////////////////`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` url `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'192.168.1.117:80'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` streamnum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Videostream user 11 for 1280 x 720 pixel, 12 for 640 x 352 pixel and 13 for 320 x 176 pixel resolution`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` name0 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'admin'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Camera Username`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` password0 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Camera Password`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`////////////////////////////////////////////////////////////////////////////////`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ENDE KAMERA PARAMETER`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`////////////////////////////////////////////////////////////////////////////////`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: If your login contains a special character it has to be `}<a parentName="p" {...{
          "href": "https://www.w3schools.com/tags/ref_urlencode.ASP"
        }}>{`URL encoded`}</a>{`. For example the character `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&`}</code>{` encodes to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`%26`}</code>{` - if your camera login is `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&instar`}</code>{` you have to add `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`%26instar`}</code>{` instead.`}</p>
    </blockquote>
    <h2 {...{
      "id": "testing-offline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#testing-offline",
        "aria-label": "testing offline permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing Offline`}</h2>
    <p>{`If you want to verify that everything is working before you upload those files you need to use a local web server on your host machine to serve them to your web browser. If you already have an Apache or NGINX web server / proxy installed, just drop your file into the working directory of those applications and rename it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.html`}</code>{`. You should now be able to access the website on your host machines IP address.`}</p>
    <p>{`As an alternative you can install `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`Node.js on your computer`}</a>{`. Then install and run the program `}<strong parentName="p">{`httpster`}</strong>{` by typing the following command into your terminal inside the folder that holds your files (rename the one that you want to use to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.html`}</code>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -g httpster
httpster -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3000`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9ca6910e0fa817c5ceed6475caca30f5/4ef49/Stream_in_Website_Flash_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABuklEQVQozy3L246aQACAYVLEvdimSRNRBLvMcBqGYZgDjAywiNi6rpsebGxjL5pe9P0fotHun+/2N9rHQbdD3Qyd3ul6W6p1U6m+UjUTmvGcsDRj+FWRZgW+YhlhhDDj8nK6HL9fjqdfu68bfVDNdqyHp6p7rofP7U6rrai2shpEOYhyI+SGi57dRAk3Doefx8P5+HwaP37rumMhOsrbjLe5eKSyy1mbFy2hDSY6yzWhTUabLG8IbWMkjB/j7jzuz+PT0O212lWqlaWWqqlUV+u+4JrxJi9qQteYVEHEopiHMYuRhEFm0O5LhtF87jjeynbdu/u3xhvTNK3/LGv6ajqdTCzTnJjmxLKmd9O7+3fvjYes9VagZLFC6TpGKowCz7Nte37Ltu3ZbGZfmy2cxXK59DzPdb2l4zzA0IhRzDkjGFc42deSh5ED4QpCCEAYhmVVSSnDMCyKIkniIAiSJIEQBkEIgG9QPbCCLVyXx+Hv/aib9m+MlO9/AABCWGvd9z1CiHPR9z1OUwAAIcT3fQCA4UcoCILZYo59/xMXpRB/0owDcJ0BpJQyzqIoStO0lBIhBCDAGINb/wAvN1nP1qiWBgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ca6910e0fa817c5ceed6475caca30f5/e4706/Stream_in_Website_Flash_03.avif 230w", "/en/static/9ca6910e0fa817c5ceed6475caca30f5/d1af7/Stream_in_Website_Flash_03.avif 460w", "/en/static/9ca6910e0fa817c5ceed6475caca30f5/ada94/Stream_in_Website_Flash_03.avif 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9ca6910e0fa817c5ceed6475caca30f5/a0b58/Stream_in_Website_Flash_03.webp 230w", "/en/static/9ca6910e0fa817c5ceed6475caca30f5/bc10c/Stream_in_Website_Flash_03.webp 460w", "/en/static/9ca6910e0fa817c5ceed6475caca30f5/8d1ba/Stream_in_Website_Flash_03.webp 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ca6910e0fa817c5ceed6475caca30f5/81c8e/Stream_in_Website_Flash_03.png 230w", "/en/static/9ca6910e0fa817c5ceed6475caca30f5/08a84/Stream_in_Website_Flash_03.png 460w", "/en/static/9ca6910e0fa817c5ceed6475caca30f5/4ef49/Stream_in_Website_Flash_03.png 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9ca6910e0fa817c5ceed6475caca30f5/4ef49/Stream_in_Website_Flash_03.png",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`By typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`httpster -p 3000`}</code>{` you now have started a web server on port 3000 on your host machine. This server is hosting the content of the folder you started it in. Since you renamed your file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.html`}</code>{` your browser will see this as the entry point of your web site when accessing the URL `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:3000`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "688px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f87eeae6c3122373eaf7864e2d83a9b6/ebf47/Stream_in_Website_Flash_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC/0lEQVQoz03NW0/aAAAF4P4LXzY1us1ZL4vZTaWARVGcSBQQ56hYOgaF0nrDom2h0LUFYQU3FzVuiaAYCQwnmU/LpmbR+bDExMSfYuLlaXN72cl5OC9fDtDQ3NIGaVRaWA11wFCHVqVpfdxaV99YWV1bWV1b9V8rq2pu3a66VVVTXX2npvbu3Xv3gY7O7u4+U79pAGvXeg1GT88zzxNV68MnTS2PwMYH98Gm+obm+oZmsLH54VOou8/Wr+9Uw12mQeujVhUwn0ySFBWNRncz67GJScFPLod4DHWOoU4uFJblmCjJwmtRlKSI8HqODXFzcyGej8XjPw4PgXw+j2HYwsLCr5MTZGxMp9f7x8e1Wq1erw/SdDgcjsdjqVQqmUzIkhQM0mGeX1paWl5Zubq6ArLZLEmSwWDw59FRkKY7dbpRBOkxGLq6unw+H8MwEASZzWZFUWRZZlk2Go2ura1ls9mLiwuA53mWZVEUPTg4SKfTMAzb7XaTyaRWqxEEEUURhuH29nZRFGVZpmma47h/+ObZ5XIlk0m/318qlYrFosFgIAgCwzCVSjU0NOTxeMxmc29vL8dxoihOTU3Nzs5mMpn19fUbbLFYFEX5+jd7e3sWi8Xn801PT9tsNoqi3G43BEEDAwPDw8MTExM4jgcCgY2NjVwud4MJgsBx/Pj4eH9//+zszOv1OhwOQRBYlpUkyWg0qtXqwcFBjUbT1tYGgiCGYYVCIZ/P32BFUXQ63dbWVrlcPj09XVxcRBDE4/GQJDkzM8MwTCKRQFEUBMG6urqKioqRkZFCobC5uXl9fQ2USqWebv2bxHz58/b2p+KX8k5clhD7C9dLbJz0C9HIQjoVYlmnc4zwefuNfVarlaZpURQvLy+B3d3dV7jP4fJmt4rvVj6uZnLx1DubHX3uwEZd+GSQZSIiJ8hUYHY+vRgSZIZh3W631Wo9Pz8H9r5/S69mLO7A/Id8Klt6m9tRMiV/OIkzMSKcoIQ08XePjnPT8fdeRuIjEYqinE7nH/wbIDNfoTPCS+8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f87eeae6c3122373eaf7864e2d83a9b6/e4706/Stream_in_Website_Flash_04.avif 230w", "/en/static/f87eeae6c3122373eaf7864e2d83a9b6/d1af7/Stream_in_Website_Flash_04.avif 460w", "/en/static/f87eeae6c3122373eaf7864e2d83a9b6/70482/Stream_in_Website_Flash_04.avif 688w"],
              "sizes": "(max-width: 688px) 100vw, 688px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f87eeae6c3122373eaf7864e2d83a9b6/a0b58/Stream_in_Website_Flash_04.webp 230w", "/en/static/f87eeae6c3122373eaf7864e2d83a9b6/bc10c/Stream_in_Website_Flash_04.webp 460w", "/en/static/f87eeae6c3122373eaf7864e2d83a9b6/01c7f/Stream_in_Website_Flash_04.webp 688w"],
              "sizes": "(max-width: 688px) 100vw, 688px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f87eeae6c3122373eaf7864e2d83a9b6/81c8e/Stream_in_Website_Flash_04.png 230w", "/en/static/f87eeae6c3122373eaf7864e2d83a9b6/08a84/Stream_in_Website_Flash_04.png 460w", "/en/static/f87eeae6c3122373eaf7864e2d83a9b6/ebf47/Stream_in_Website_Flash_04.png 688w"],
              "sizes": "(max-width: 688px) 100vw, 688px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f87eeae6c3122373eaf7864e2d83a9b6/ebf47/Stream_in_Website_Flash_04.png",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "upload-the-page-to-your-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#upload-the-page-to-your-server",
        "aria-label": "upload the page to your server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Upload the Page to your Server`}</h2>
    <p>{`Now please use a FTP client in order to upload the files to your web space. In our example we are using the software `}<strong parentName="p">{`FileZilla`}</strong>{`. If you are not familiar with HTML we recommend you to upload all files in the same folder next to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.html`}</code>{` of your website.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c164a39e166ca5b5e58f53b795344d5/29d31/Stream_in_Website_HD_03_DE.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdphyZQP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQESFC/9oACAEBAAEFAkijDqP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAAIEH/2gAIAQEABj8CIv8A/8QAGxAAAQUBAQAAAAAAAAAAAAAAAAERIWGREHH/2gAIAQEAAT8hQp/BkxgamCdf/9oADAMBAAIAAwAAABCjz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAAIBBAMAAAAAAAAAAAAAAAABMRARQVFxgcH/2gAIAQEAAT8QhNPpiJd3MdCObvwWaf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c164a39e166ca5b5e58f53b795344d5/e4706/Stream_in_Website_HD_03_DE.avif 230w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/d1af7/Stream_in_Website_HD_03_DE.avif 460w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/70e80/Stream_in_Website_HD_03_DE.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c164a39e166ca5b5e58f53b795344d5/a0b58/Stream_in_Website_HD_03_DE.webp 230w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/bc10c/Stream_in_Website_HD_03_DE.webp 460w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/426ac/Stream_in_Website_HD_03_DE.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c164a39e166ca5b5e58f53b795344d5/e83b4/Stream_in_Website_HD_03_DE.jpg 230w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/e41a8/Stream_in_Website_HD_03_DE.jpg 460w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/29d31/Stream_in_Website_HD_03_DE.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c164a39e166ca5b5e58f53b795344d5/29d31/Stream_in_Website_HD_03_DE.jpg",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`All you need to do is either to link either of those files -  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h264_video.html`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mjpeg_video.html`}</code>{` - into your website.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      